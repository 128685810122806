const translationsData = {
  en: {
    footer__social_1: 'https://www.facebook.com/smartstp.official/',
    footer__social_2: 'https://www.instagram.com/smartstp_official/',
    footer__social_3: 'https://x.com/SmartSTP',
    footer__social_4: 'https://www.linkedin.com/company/smartstp/',
    footer__bottom:
      '<p>MRL Investments (MU) Ltd duly registered in Mauritius with a registration number 187076GBC holder of an FSC license with number GB21027168 and registered address at 7th Floor, Tower 1, NeXTeracom, Cybercity Ebene 72201 Mauritius. <br>\n' +
      'MRL Investments (MU) Ltd does not offer Contracts for Difference to residents of certain jurisdictions including the USA, Iran, Canada, Iran, Iraq, North Korea. <br></p>\n' +
      '<p>RISK WARNING: Forex/CFDs trading involves significant risk to your invested capital as you can potentially lose more than your initial investment. The content of this website is generic in nature and reliance on the same is at your own risk. Please read the legal documents and make sure that you fully understand our <a href="https://smartstp-fo-content-prod.s3.eu-west-1.amazonaws.com/legal/MRL_INVESTMENTS_MU_LTD_Risk_Disclosure_Policy_SmartSTP.pdf">Risk Disclosure Policy</a>.</p>\n' +
      '<p>*Past performance is not an indication of future results.<br></p>',
  },
};

export default translationsData;
