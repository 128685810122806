/* eslint-disable no-param-reassign */

import React, { useContext } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';
// import Slick from 'react-slick';

import { Container } from '@nfs-react/components';

import { MOBILE, TABLET, DESKTOP } from 'helpers/styles/media';
import { LangContext } from 'helpers/providers/LangProvider';
import { getImgUrl } from 'helpers/index';

import translationsData from './translations';
// import { SliderStyles } from './UI';

const Footer = () => {
  // const sliderSettings = {
  //   arrows: false,
  //   dots: false,
  //   draggable: true,
  //   infinite: true,
  //   slidesToShow: 9,
  //   slidesToScroll: 3,
  //   autoplay: true,
  //   autoplaySpeed: 4000,
  // };

  const { funnel_language: lang } = useContext(LangContext);

  return (
    <>
      <Wrapper>
        <PaymentsContainer>
          <Payments>
            {/* <SliderStyles /> */}
            {/* <Slick {...sliderSettings}> */}
            {[...Array(9)].map((i, idx) => {
              const index = idx + 1;
              return (
                <img
                  key={index}
                  src={getImgUrl(
                    `GlobalFooter/SmartSTP/payment-icon-${index}.svg`,
                    true,
                    false,
                  )}
                  alt=''
                />
              );
            })}
            {/* </Slick> */}
          </Payments>
        </PaymentsContainer>
        <Top>
          <TopContainer>
            <TopLeft>
              <Logo>
                <a
                  href='https://www.smartstp.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/SmartSTP/logo.svg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
              </Logo>
            </TopLeft>
            <TopRight>
              <TopRightSocials>
                <a
                  href={parse(translationsData[lang].footer__social_1)}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/SmartSTP/icon-1.svg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
                <a
                  href={parse(translationsData[lang].footer__social_2)}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/SmartSTP/icon-2.svg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
                <a
                  href={parse(translationsData[lang].footer__social_3)}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/SmartSTP/icon-3.svg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
                <a
                  href={parse(translationsData[lang].footer__social_4)}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/SmartSTP/icon-4.svg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
              </TopRightSocials>
            </TopRight>
          </TopContainer>
        </Top>
        <Bottom>
          <BottomContainer>
            {parse(translationsData[lang].footer__bottom)}
          </BottomContainer>
        </Bottom>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  font-family: 'Open Sans', sans-serif;
  color: #242424;
  position: relative;
  background-color: #fff;
  padding: 40px 0 0;

  svg {
    max-width: 100%;
    display: block;
  }
`;

const PaymentsContainer = styled(Container)`
  @media ${DESKTOP} {
    max-width: 1400px;
  }

  @media ${MOBILE} {
    max-width: 100%;
    overflow: auto;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Payments = styled.div`
  border: 50px solid #f6f7f8;
  border-radius: 65px;
  padding: 60px;
  display: flex;
  justify-content: space-between;

  img {
    max-height: 32px;
  }

  @media ${TABLET} {
    padding: 36px 7px;
  }

  @media ${MOBILE} {
    padding: 40px 10px;
    max-width: 1100px;
    width: 1100px;
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
`;

const Top = styled.div`
  padding: 30px 0;
`;

const TopContainer = styled(Container)`
  display: flex;
  justify-content: space-between;

  @media ${MOBILE} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const TopLeft = styled.div`
  margin-right: 60px;

  @media ${MOBILE} {
    margin-right: 0;
    margin-bottom: 35px;
  }
`;

const Logo = styled.div`
  img {
    max-width: 254px;
    display: block;
  }

  @media ${MOBILE} {
    img {
      max-width: 208px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const TopRight = styled.div`
  max-width: 245px;
`;

const TopRightSocials = styled.div`
  display: flex;

  a {
    display: block;

    img {
      display: block;
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    svg {
      width: 35px;
      height: 35px;
    }
  }

  @media ${MOBILE} {
    a {
      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
`;

const Bottom = styled.div`
  position: relative;
  color: rgba(96, 96, 96, 0.6);
  padding: 20px 0 50px;

  font-size: 12px;
  line-height: 16px;

  a {
    color: rgba(96, 96, 96, 0.6);
  }
`;

const BottomContainer = styled(Container)`
  position: relative;

  &:before {
    content: '';
    display: block;
    background-color: rgba(17, 17, 17, 0.16);
    width: calc(100% + 80px);
    height: 2px;
    position: absolute;
    top: -20px;
    left: -40px;
  }

  @media ${MOBILE} {
    &:before {
      width: calc(100% + 40px);
      left: -20px;
    }
  }
`;

export default Footer;
