const translationsData = {
  en: {
    footer__text:
      '<p>FXRoad is a regulated broker with a variety of trading instruments, competitive spreads, and advanced trading platforms, offering a seamless and transparent trading experience.</p>',
    footer__right: '<p>Find us on</p>',
    footer__social_1: 'https://www.facebook.com/FXRoadOfficial',
    footer__social_2:
      'https://instagram.com/fxroad_official?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr',
    footer__social_3:
      'https://x.com/fxroadofficial?s=21&t=UVgp7EGLqSx0ERlHR6iuxw',
    footer__social_4: 'https://www.linkedin.com/company/fxroad',
    footer__bottom:
      '<p>LEGAL INFORMATION:</p>\n' +
      '<p>This website www.fxroad.com is owned and operated by 4Square SY Ltd. \n' +
      '4Square SY Ltd duly registered in Seychelles with a registration number 8426168-1 holder of an FSA license with number SD052 and registered address at CT House, Office 9A, Providence, Mahe, Seychelles.</p>\n' +
      '<p>4 Square SY Limited does not offer its services to residents of certain jurisdictions including the USA, Canada, the European Union, Iran, Iraq, Syria, North Korea, Sudan.</p>\n' +
      '<p><br></p>\n' +
      '<p>RISK WARNING:</p>\n' +
      '<p>Forex/CFDs trading involves significant risk to your invested capital. Please read and make sure that you fully understand our <a href="https://fxroad-fo-content-prod.s3.eu-west-1.amazonaws.com/FXRoad_Risk_Disclosure_Policy_updated.pdf">Risk Disclosure Policy</a>. 4SQUARE SY LIMITED’s <a href="https://fxroad-fo-content-prod.s3.eu-west-1.amazonaws.com/FXRoad_Terms_and_Conditions_upd.pdf">Terms &amp; Conditions</a>, <a href="https://fxroad-fo-content-prod.s3.eu-west-1.amazonaws.com/FXRoad_Privacy_Policy.pdf">Privacy Policy</a>, <a href="https://fxroad-fo-content-prod.s3.eu-west-1.amazonaws.com/FXRoad-_Cookies_Policy_updated.pdf">Cookies Policy</a>, <a href="https://fxroad-fo-content-prod.s3.eu-west-1.amazonaws.com/FXRoad_Risk_Disclosure_Policy_updated.pdf">Risk Disclosure Policy</a> and <a href="https://fxroad-fo-content-prod.s3.eu-west-1.amazonaws.com/FXRoad_Complaints_Handling_Procedure_updated.pdf">Complaint Handling Procedure</a> are available on our website.</p>',
  },
};

export default translationsData;
