/* eslint-disable no-param-reassign */

import React, { useContext } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { Container } from '@nfs-react/components';

import { MOBILE, TABLET, DESKTOP } from 'helpers/styles/media';
import { LangContext } from 'helpers/providers/LangProvider';
import { getImgUrl } from 'helpers/index';

import translationsData from './translations';

const Footer = () => {
  const { funnel_language: lang } = useContext(LangContext);

  return (
    <>
      <Wrapper>
        <Payments>
          <img
            src={getImgUrl('GlobalFooter/FXRoad/payments.jpg', true, false)}
            alt=''
          />
        </Payments>
        <Top>
          <TopContainer>
            <TopLeft>
              <Logo>
                <a
                  href='https://www.fxroad.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/FXRoad/logo-dark.svg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
              </Logo>
              <TopText>{parse(translationsData[lang].footer__text)}</TopText>
            </TopLeft>
            <TopRight>
              <TopRightText>
                {parse(translationsData[lang].footer__right)}
              </TopRightText>
              <TopRightSocials>
                <a
                  href={parse(translationsData[lang].footer__social_1)}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/FXRoad/icon-1.jpg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
                <a
                  href={parse(translationsData[lang].footer__social_2)}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/FXRoad/icon-2.jpg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
                <a
                  href={parse(translationsData[lang].footer__social_3)}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/FXRoad/icon-3.jpg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
                <a
                  href={parse(translationsData[lang].footer__social_4)}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={getImgUrl(
                      'GlobalFooter/FXRoad/icon-4.jpg',
                      true,
                      false,
                    )}
                    alt=''
                  />
                </a>
              </TopRightSocials>
            </TopRight>
          </TopContainer>
        </Top>
        <Bottom>
          <Container>{parse(translationsData[lang].footer__bottom)}</Container>
        </Bottom>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  font-family: 'Open Sans', sans-serif;
  color: #242424;
  position: relative;

  svg {
    max-width: 100%;
    display: block;
  }
`;

const Payments = styled.div`
  border-top: 2px solid #fbfbfb;
  border-bottom: 2px solid #fbfbfb;
  padding-left: 10px;
  padding-right: 10px;
  overflow: auto;
  background-color: #fff;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
  }

  @media ${TABLET} {
    img {
      max-width: 750px;
    }
  }

  @media ${MOBILE} {
    img {
      max-height: 60px;
    }
  }
`;

const Top = styled.div`
  padding: 60px 0;

  @media ${TABLET} {
    padding: 40px 0;
  }

  @media ${MOBILE} {
    padding: 25px 0;
  }
`;

const TopContainer = styled(Container)`
  display: flex;
  justify-content: space-between;

  @media ${DESKTOP} {
    max-width: 800px;
  }

  @media ${MOBILE} {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const TopLeft = styled.div`
  margin-right: 60px;

  @media ${MOBILE} {
    margin-right: 0;
    margin-bottom: 25px;
  }
`;

const Logo = styled.div`
  margin-bottom: 8px;

  img {
    max-width: 232px;
    display: block;
  }

  @media ${MOBILE} {
    img {
      max-width: 186px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const TopText = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const TopRight = styled.div`
  max-width: 245px;
`;

const TopRightText = styled.div`
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 8px;

  @media ${MOBILE} {
    display: none;
  }
`;

const TopRightSocials = styled.div`
  display: flex;

  a {
    display: block;
    width: 46px;
    height: 46px;

    img {
      display: block;
      max-width: 100%;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

const Bottom = styled.div`
  background-color: #073cbf;
  padding: 60px 0;

  @media ${TABLET} {
    padding: 40px 0;
  }

  @media ${MOBILE} {
    padding: 25px 0;
  }

  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  line-height: 16px;

  a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }

  p {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export default Footer;
